<template>
	<div class="ticket-manage-main">
		<div class="title-text">开票管理</div>
		<el-tabs v-model="activeName" @tab-click="menuClick">
		    <el-tab-pane v-for="(item, index) in menuList" :key="index" :label="item.title" :name="item.path"></el-tab-pane>
		</el-tabs>
		<div class="search-content flex flex-col-center flex-wrap">
			<span class="search-title">开票企业</span>
			<el-input v-model="fromData.company_name" class="input-text" placeholder="请输入开票企业名称"></el-input>
			<span class="search-title">关联订单</span>
			<el-input v-model="fromData.order_no" class="input-text" placeholder="输入关联订单编号"></el-input>
			<span class="search-title">申请时间</span>
			<el-date-picker v-model="timeText" type="datetimerange" prefix-icon="el-icon-date" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
			<el-button class="search-btn" type="primary" @click="searchClick">查询</el-button>
			<el-button class="rest-btn" @click="restClick">重置</el-button>
		</div>
		<div class="list-main" v-loading="isLoad" >
			<ticket-all-table-item-vue :msg="tableData" v-if="fromData.status=='0'"></ticket-all-table-item-vue>
			<ticket-exa-table-item-vue :msg="tableData" v-if="fromData.status=='2'"></ticket-exa-table-item-vue>
			<ticket-reject-table-item-vue :msg="tableData" :showType="fromData.status" v-if="fromData.status=='8'||fromData.status=='1'"></ticket-reject-table-item-vue>
		</div>
		<div class="page-btn-content flex flex-col-center flex-row-end">
			 <el-pagination
			      @current-change="currentChange"
				  background
				  :pager-count="11"
			      :page-size="10"
			      layout="total, prev, pager, next"
			      :total="pageAllSize">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import ticketAllTableItemVue from "./components/ticket-all-table-item.vue";
	import ticketExaTableItemVue from "./components/ticket-exa-table-item.vue";
	import ticketRejectTableItemVue from "./components/ticket-reject-table-item.vue";
	export default {
		components: {
			ticketAllTableItemVue, ticketExaTableItemVue, ticketRejectTableItemVue
		},
		data(){
			return {
				menuList: [
					{title: "全部", path: '0'}, {title: "待审核", path: '2'}, {title: "已驳回", path: '8'}, {title: "已开具", path: '1'}
				],//菜单
				activeName: '0',
				timeText: '',
				pageAllSize: 0,
				tableData: [],
				fromData: {page: 1, limit: 10, status: '0', company_name: '', order_no: '', start_time: '', end_time: ''},//提交的订单数据
				isLoad: false,
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData(){
				this.isLoad = true;
				this.$api.invoice_list(this.fromData).then(res=>{
					this.isLoad = false;
					this.pageAllSize = res.data.count;
					this.tableData = res.data.data;
				}).catch(err=>{
					this.isLoad = false;
				})
			},
			//查询
			searchClick(){
				this.fromData.page = 1;
				this.initData();
			},
			//重置
			restClick() {
				this.timeText = '';
				this.fromData.name = '';
				this.fromData.order_no = '';
				this.fromData.start_time = '';
				this.fromData.end_time = '';
			},
			changeTime(e){
				this.fromData.start_time = timeFormat(e[0], 'yyyy-mm-dd hh:MM:ss');
				this.fromData.end_time = timeFormat(e[1], 'yyyy-mm-dd hh:MM:ss');
			},
			//翻页 
			currentChange(val) {
				this.fromData.page = val;
				this.initData();
			},
			//菜单
			menuClick(e){
				this.fromData.page = 1;
				this.fromData.status = e.name;
				this.initData();
			}
			
		},
	}
</script>

<style lang="scss" scoped>
	.ticket-manage-main{width: 100%;height: 100%;background-color: #fff;
		.title-text{position: relative;font-size: 18px;color: #242D33;padding: 26px 0 20px 30px;
			&:before{position: absolute;content: '';width: 4px;height: 20px;background-color: #3984F5;left: 0;top: 30px;}
		}
		.search-content{padding: 5px 30px 20px 30px;
			.search-title{font-size: 12px;color: #21333F;margin-right: 12px;}
			.input-text{width: 210px;margin-right: 40px;}
			.search-btn{width: 70px;height: 34px;background: #3984F5;border-radius: 4px;padding: 0;margin: 0 10px;}
			.rest-btn{width: 70px;height: 34px;background: #EEF1F3;border-radius: 4px;padding: 0;color: #6C7880;}
		}
		.list-main{padding: 0 30px;}
		.page-btn-content{padding: 25px 30px 0 0;}
	}
	::v-deep(.el-input__inner){height: 34px;line-height: 34px;}
	::v-deep(.el-date-picker){width: 234px;}
	::v-deep(.el-input__icon){height: 34px;line-height: 34px;}
	::v-deep(.el-tabs__nav-scroll){padding-left: 30px;}
	
</style>