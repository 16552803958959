<template>
	<div class="">
		<el-table :data="msg" height="530" style="width: 100%" border :header-cell-style="{height:'48px;',background:'#F6F7F9',color:'#6C7880'}" >
			<el-table-column prop="company.company" label="开票企业" width="258"></el-table-column>
			<el-table-column prop="title" label="发票抬头" width="258"></el-table-column>
			<el-table-column label="发票金额" width="120">
				<template slot-scope="scope">
					<span>￥{{scope.row.amount}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="medium" label="发票介质" width="120"></el-table-column>
			<el-table-column prop="order_no" label="关联订单" width="350"></el-table-column>
			<el-table-column prop="create_time" label="申请时间" width="210"></el-table-column>
			<el-table-column label="操作" fixed="right" width="210">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="openDetails(scope.row)">审核</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		name: 'ticket-exa-table-item',
		props: {
			msg: {}
		},
		data(){
			return {
				
			}
		},
		created(){
			
		},
		methods: {
			//详情
			openDetails(e){
				this.$router.push({path: '/ticketDetails', query: {id: e.id}})
			},
			
		},
	}
</script>

<style lang="scss" scoped>
	
	
</style>